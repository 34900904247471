html {
  margin: 0px;
  padding: 0px;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;    
  padding: 10px;
  font-size : 15px; /* "Century Gothic", Futura, sans-serif; */
  margin: 0;
  background-color: rgb(248,248,248);
}

.app-theme--header {
  color : blue;
  font-weight : bold;
  
}
