.loading-icon {
    color: teal;
    font-size: 2rem;
    animation: loading 2s infinite;
}
  
@keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(720deg);
    }
}